.regCustomTextField .react-tel-input .form-control{
    padding: 15px 60px !important;
    width: 100%!important;
    margin: 5px 0px!important;
    border: 2px solid #2b374412 !important;
    box-shadow: 0px 10px 20px 0px #2b374412 !important;
    border-radius: 26px !important;
    
   } 

   .regCustomTextField{
    text-align: initial !important; 
   }  


   .regCustomTextField   .react-tel-input .form-control:focus,
   .regCustomTextField   .react-tel-input .form-control:hover{
    background-color:#fff!important;
    border-color: #0b80ff  !important;
    outline: 0!important;
    box-shadow: none!important;
    }
   
  
    .regCustomTextField .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
       border-color: #2196f3 !important; 
      box-shadow: none!important;  
      border: none!important;  
    }

   