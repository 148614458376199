
body {
  font-family: "Satoshi", serif !important;
  background-color: #fff !important;
}

body::-webkit-scrollbar{
 width: 0.3em;;
 height: 0.3em;
}
body::-webkit-scrollbar-thumb{
 background-color: rgba(0,0,0,.1);
 }
body::-webkit-scrollbar-track{
 box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

::-webkit-scrollbar{
 width: 0.3em;
 height: 0.3em;
}
::-webkit-scrollbar-thumb{
 background-color: rgba(0,0,0,.1); 
}
::-webkit-scrollbar-track{
 box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }
 

  .Toastify__toast{
    box-shadow: none;
    background-color: unset;
  }
 
  .Toastify__toast svg{
    color: #000;
  }

  .Mui-disabled{
    color: white;
  }


  .main{
    position: fixed;
top: 0;
left: 0;
z-index: 19999;
width: 100vw;
height: 100vh;
background-color: #000;
      opacity: 0.5;
  
  }

#spinner {
    position: absolute;
    left: 49%;
    top: 44%;
    height: 40px;
    width: 40px;
 
    animation: rotation .8s infinite linear;
    border-left: 6px solid rgba(0,174,239,.15);
    border-right: 6px solid rgba(0,174,239,.15);
    border-bottom: 6px solid rgba(0,174,239,.15);
    border-top: 6px solid rgba(0,174,239,.8);
    border-radius: 100%;
    z-index: 1000000;
 }
 
 @-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
 }
 @-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
 }
 @-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
 }
 @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
 }



 #toolbar {
  display: none !important;
 }

 .react-tel-input .form-control{
  padding: 10px 60px !important;
  width: 100%!important;
  margin-top: 5px!important;
  border-radius: 0 !important;
  
 } 
 .react-tel-input .form-control:focus{
  background-color:#fff!important;
  border-color: none !important;
  outline: 0!important;
  box-shadow: none!important;
  }
 

  .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
     border-color: #CACACA !important; 
    box-shadow: none!important;  
    border: none!important;  
  }

  

  /* npm i react-copy-to-clipboard
 */